import React from "react"
import { Link } from "gatsby"
import {Container} from 'reactstrap'

import Layout from '../components/layout'
import Cloud from '../components/cloud'

export default ({ data }) => (
    <Layout title="Team">
        <Cloud title="ÜBER UNS"/>

        <Container className="bg-white about">
            <h1 className="text-center">Die Cloudlift GmbH ist eine Software Unternehmung mit Fokus in Web Applikationsentwicklung auf Cloud basierenden Infrastrukturen</h1>

            <div className="team text-center">
                <h3 className="team-title">Team</h3>
                <div className="team-divider"></div>

                <div className="team-members">
                    <div className="team-member">
                        <Link to="/team/michaelhaessig">
                            <h4>Michael Hässig</h4>
                            <span>Firmengründer</span>
                        </Link>
                    </div>
                </div>
            </div>

        </Container>
    </Layout>
)
